import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getAccessToken } from "../Utils/Utils.js";
import axios from "axios";
import Loader from "../Utils/Loader";
import "./ProfileCard.css";

export default function ProfileCard() {
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/v2/${getAccessToken()}/patient`;
  const headers = {
    "Content-Type": "multipart-form-data",
    Accept: "application/json",
  };

  const [email, setEmail] = useState();
  const [name, setName] = useState();

  const [request, setRequest] = useState({
    loading: false,
    data: null,
    error: false,
  });
  let content = null;

  useEffect(() => {
    setRequest({
      loading: true,
      data: null,
      error: false,
    });
    axios
      .get(url, { headers: headers })
      .then((response) => {
        setRequest({
          loading: false,
          data: response.data,
          error: false,
        });
        setName(response.data.name);
        setEmail(response.data.email);
      })
      .catch(() => {
        setRequest({
          loading: false,
          data: null,
          error: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  if (request.error) {
    content = <div>Daten nicht gefunden. Bitte aktualisiere die Seite oder melde dich neu an.</div>;
  }

  if (request.loading) {
    content = <Loader />;
  }

  if (request.data) {
    content = (
      <Container fluid className="h-100 profile-card">
        <center>
          <Row className="h-100">
            <Col>
              <a href="/preferences/profile">
                <img
                  src="/images/avatar_placeholder.png"
                  alt="profile avatar"
                  className="profile-card-image"
                />
              </a>
            </Col>
          </Row>
          <Row className="h-100">
            <Col className="profile-card-name">{name}</Col>
          </Row>
          <Row className="h-100">
            <Col className="profile-card-mail">{email}</Col>
          </Row>
        </center>
      </Container>
    );
  }

  return <React.Fragment>{content}</React.Fragment>;
}
