import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Carousel from "react-bootstrap/Carousel";
import ReactPlayer from "react-player";
import "./Utils.css";
import { useTranslation } from "react-i18next";
import { Collapse } from "react-bootstrap";
import parse from "html-react-parser";

function sendResetPasswordRequest(mail, props) {
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    "/de/v2/patient/password_reset.json";
  var params = new FormData();
  params.append("email", mail.toLowerCase());
  params.append("style", props.application);
  axios
    .post(url, params)
    .then(function (response) {
      props.setPasswordSuccess();
    })
    .catch(function (error) {
      props.setPasswordFailed();
    });
}

function sendPatientRegistration(mail, password, props, applicationName) {
  const url = process.env.REACT_APP_SOPHYAPP_API_URL + "/de/v2/patient.json";
  var params = new FormData();
  params.append("patient[email]", mail.toLowerCase());
  params.append("patient[password]", password);
  params.append("patient[style]", applicationName);
  axios
    .post(url, params)
    .then(function (response) {
      props.setRegistrationSuccess(true);
    })
    .catch(function (error) {
      props.setRegistrationFailed(true);
    });
}

export function ResetPassword(props) {
  const [mail, setMail] = useState("");
  const { t, i18n } = useTranslation(); //eslint-disable-line no-unused-vars

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    sendResetPasswordRequest(mail, props);
    props.onHide();
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("password.forgot")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("password.mail")}</Form.Label>
            <Form.Control
              type="email"
              placeholder="user@sophyapp.com"
              value={mail}
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required
              autoFocus
              onChange={(e) => setMail(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bs-button-color" type="submit">
            {t("actions.send")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export function NewRegistration(props) {
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [pwRepeat, setPwRepeat] = useState("");
  const { t, i18n } = useTranslation(); //eslint-disable-line no-unused-vars

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (password === pwRepeat) {
      sendPatientRegistration(mail, password, props, props.applicationName);
      props.onHide();
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("user.registration")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("password.mail")}</Form.Label>
            <Form.Control
              type="email"
              placeholder="user@patient.com"
              autoFocus
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required
              onChange={(e) => setMail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("password.at-least-characters")}</Form.Label>
            <Form.Control
              type="password"
              pattern=".{8,20}"
              placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{t("password.repeat")}</Form.Label>
            <Form.Control
              type="password"
              pattern=".{8,20}"
              placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
              onChange={(e) => setPwRepeat(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bs-button-color" type="submit">
            {t("actions.send")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export function ProgramModal(props) {
  // const [feedbackLvl, setLvl] = useState(5);
  // const [feedbackText, setFeedbackText] = useState("");
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Programm als Slideshow
        </Modal.Title>
      </Modal.Header>
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {props.exercises.map((exercise, index) => (
          <Carousel.Item key={index}>
            <ReactPlayer
              url={exercise.props.exercise.source.video_url}
              width="100%"
              pip={true}
              // muted
              controls={true}
              playing={true}
              autoplay
            />
            <Carousel.Caption>
              <h3>{exercise.props.exercise.source.video_url}</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </Modal>
  );
}

export function SlideshowModal(props) {
  // const [feedbackText, setFeedbackText] = useState("");
  // const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const videoRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation(); //eslint-disable-line no-unused-vars

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, [props.video]);

  // const handleFeedbackSubmit = (e) => {
  //   e.preventDefault();
  //   props.onSubmitFeedback(props.video.id, feedbackText);
  //   setFeedbackText("");
  //   // setShowFeedbackForm(false);
  // };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {" "}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Programm als Slideshow
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ textAlign: "center" }}>
          <h4>
            {props.exercises
              ? parse(
                  `${props.exercises.props.exercise?.name || ""} (${
                    props.currentIndex + 1
                  }/${props.programLength})`
                )
              : ""}
          </h4>
          <video
            ref={videoRef}
            src={
              props.exercises
                ? props.exercises.props.exercise.source.video_url
                : ""
            }
            controls
            loop
            autoPlay
            style={{ width: "90%" }}
          />
          <div className="prescription">
            {props.exercises ? props.exercises.props.exercise.prescription : ""}
          </div>
        </div>
        <div>
          <button
            className="btn btn-link show-more-text"
            type="button"
            onClick={() => setOpen(!open)}
            aria-controls="collapse-description"
            aria-expanded={open}
          >
            Description
          </button>
          <Collapse in={open}>
            <div id="collapse-description">
              <ul>
                {props.exercises &&
                  props.exercises.props.exercise.source.steps.map(
                    (step, index) => <li key={index}>{step}</li>
                  )}
              </ul>
            </div>
          </Collapse>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <button
            className="btn-previous-next"
            onClick={props.onPrevious}
            disabled={props.currentIndex === 0}
          >
            &larr; {t("program.previous")}
          </button>
          {/* <button onClick={() => setShowFeedbackForm(!showFeedbackForm)}>
            {showFeedbackForm ? 'Cancel' : 'Add Single Feedback +'}
          </button> */}
          <button
            className="btn-previous-next"
            onClick={props.onNext}
            disabled={props.currentIndex === props.programLength - 1}
          >
            {t("program.next")} &rarr;
          </button>
        </div>
        {/* {showFeedbackForm && (
          <form onSubmit={handleFeedbackSubmit} className="mt-3">
            <textarea
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
              placeholder="Enter your feedback here"
              rows="4"
              className="form-control"
            />
            <button type="submit" className="btn btn-primary mt-2">Submit Feedback</button>
          </form>
        )} */}
      </Modal.Body>
    </Modal>
  );
}
