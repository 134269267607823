import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import CameraIcon from "@mui/icons-material/Camera";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
// import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import SettingsIcon from "@mui/icons-material/Settings";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./Header";

export default function Menu(props) {
  const [value, setValue] = React.useState(0);
  const { t, i18n } = useTranslation(); // eslint-disable-line no-unused-vars
  const isDesktop = useMediaQuery("(min-width:900px)");

  return (
    <Container fluid>
      {isDesktop && (
        <>
          <Header />
          <Row>
            <Col md={1} className="app-menu vh-100 d-xxs-none d-md-block">
              <NavLink to="/programs" activeclassname="active">
                <Row className="menu-row align-items-center">
                  <Col xxl={4} className="menu-icon">
                    <center>
                      <img src="/images/programs_icon.svg" alt="programs" />
                    </center>
                  </Col>
                  <Col className="menu-title d-none d-xxl-block">
                    {t("menu.programs")}
                  </Col>
                </Row>
              </NavLink>
              {/* <NavLink to="/materials" activeclassname="active">
                <Row className="menu-row align-items-center">
                  <Col xxl={4} className="menu-icon">
                    <center>
                      <img
                        src="/images/camera.svg"
                        alt="materials"
                        style={{ width: "20px", height: "20px" }}
                      />
                    </center>
                  </Col>
                  <Col className="menu-title d-none d-xxl-block">
                    {t("menu.materials")}
                  </Col>
                </Row>
              </NavLink> */}
              <NavLink to="/myphysio" activeclassname="active">
                <Row className="menu-row align-items-center">
                  <Col xxl={4} className="menu-icon">
                    <center>
                      <img src="/images/profile_icon.svg" alt="myphysio" />
                    </center>
                  </Col>
                  <Col className="menu-title d-none d-xxl-block">
                    {t("menu.myphysio")}
                  </Col>
                </Row>
              </NavLink>
              <NavLink to="/preferences" activeclassname="active">
                <Row className="menu-row align-items-center">
                  <Col xxl={4} className="menu-icon">
                    <center>
                      <img src="/images/settings_icon.svg" alt="settings" />
                    </center>
                  </Col>
                  <Col className="menu-title d-none d-xxl-block">
                    {t("menu.settings")}
                  </Col>
                </Row>
              </NavLink>
              <Row className="menu-footer d-none d-xxxl-block">
                <Col className="menu-footer-title">© hoferdigital gmbh</Col>
              </Row>
            </Col>
            <Col className="program-overview">{props.content}</Col>
          </Row>
        </>
      )}

      {!isDesktop && (
        <>
          <Header />
          <Box
            sx={{
              width: "100%",
              position: "fixed",
              bottom: 0,
              zIndex: 1000,
              left: 0,
            }}
          >
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              sx={{
                "& .Mui-selected, .Mui-selected > svg": {
                  color: "var(--main-color)",
                },
              }}
            >
              <BottomNavigationAction
                icon={<FormatListNumberedIcon />}
                component={NavLink}
                to="/programs"
                value="/programs"
              />
              {/* <BottomNavigationAction
                icon={<CameraIcon />}
                component={NavLink}
                to="/materials"
              /> */}
              <BottomNavigationAction
                icon={<PeopleAltIcon />}
                component={NavLink}
                to="/myphysio"
              />
              <BottomNavigationAction
                icon={<SettingsIcon />}
                component={NavLink}
                to="/preferences/profile"
              />
            </BottomNavigation>
          </Box>
          <Col className="program-overview">{props.content}</Col>
        </>
      )}
    </Container>
  );
}
