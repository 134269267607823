import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Preferences.css";
import { NavLink } from "react-router-dom";
import { BrandingContext } from "../Contexts/Context";
import { useTranslation } from "react-i18next";

export default function Sidebar(props) {
  const { applicationName } = useContext(BrandingContext);
  const { t, i18n } = useTranslation(); //eslint-disable-line no-unused-vars
  let content = null;
  content = (
    <Container fluid className="h-100 sidebar-menu">
      <Row className="h-100">
        <Col md={2} xxl={2} xxxl={1} className="sidebar-properties h-100">
          <Row>
            <Col md={12} className="sidebar-header">
              Einstellungen
            </Col>
          </Row>
          <NavLink to="/preferences/profile" activeclassname="subactive">
            <Row className="program-progress-title sub-menu-item">
              <Col md={12}>{t("others.profile")}</Col>
            </Row>
          </NavLink>
          <NavLink to="/preferences/version" activeclassname="subactive">
            <Row>
              <Col
                xxs={12}
                xs={12}
                lg={12}
                className="preference-row-mobile sub-menu-item"
              >
                {t("others.version")}
              </Col>
            </Row>
          </NavLink>
          {applicationName === "sophyapp" ? (
            <NavLink to="/preferences/about" activeclassname="subactive">
              <Row>
                <Col md={12} className="sub-menu-item">
                  {t("others.about-sop")}
                </Col>
              </Row>
            </NavLink>
          ) : null}
          <a
            href="https://hoferdigital.atlassian.net/wiki/spaces/SS/pages/2949447681/Wiki+f+r+Patienten+Athleten+und+Klienten"
            target="_blank"
            rel="noreferrer"
          >
            <Row>
              <Col md={12} className="sub-menu-item">
                {t("others.help")}
              </Col>
            </Row>
          </a>
        </Col>
        <Col md={10} xxl={10} xxxl={11} className="sb-main-content h-100">
          <Row>
            <Col md={12} className="sb-main-content-header">
              {props.title}
            </Col>
          </Row>
          <Row>{props.contentdisplay}</Row>
        </Col>
      </Row>
    </Container>
  );
  return <div className="h-100">{content}</div>;
}
