import { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Programs.css";
import { useParams } from "react-router-dom";
import Loader from "../Utils/Loader";
import { useAxiosGet } from "../../hooks/HttpRequests";
import {
  getAccessToken,
  isActive,
  getEndDateOfProgram,
  programInterval,
  getTargetName,
} from "../Utils/Utils.js";
import ExerciseCard from "./ExerciseCard";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import axios from "axios";
import parse from "html-react-parser";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { ProgramModal, SlideshowModal } from "../Utils/Modals";
import { useTranslation } from "react-i18next";

function renderExercises(exercises, id) {
  let exercise_content = [];
  for (let i = 0; i < exercises?.length; i++) {
    if (exercises[i].id.toString() === id) {
      for (let j = 0; j < exercises[i].exercises?.length; j++) {
        exercise_content.push(
          <ExerciseCard
            exercise={exercises[i].exercises[j]}
            key={exercises[i].exercises[j].id}
          />
        );
      }
    }
  }
  return exercise_content;
}

function getProgramSessions(exercises, id) {
  let sessions = [];
  for (let i = 0; i < exercises?.length; i++) {
    if (exercises[i].id.toString() === id) {
      for (let j = 0; j < exercises[i].sessions?.length; j++) {
        sessions.push(exercises[i].sessions[j]);
      }
    }
  }
  return sessions;
}

function setExerciseLayout(data, columns) {
  let content_block = [];
  let content = null;
  for (let i = 0; i < data?.length; i += columns) {
    content = (
      <Row className="program-exercise-row" key={i}>
        <Col md={12} lg={6} xxl={4}>
          {data[i]}
        </Col>
        {(() => {
          if (data[i + 1] !== undefined && columns >= 2) {
            return (
              <Col md={12} lg={6} xxl={4}>
                {data[i + 1]}
              </Col>
            );
          } else {
          }
        })()}
        {(() => {
          if (data[i + 2] !== undefined && columns === 3) {
            return (
              <Col md={12} lg={6} xxl={4}>
                {data[i + 2]}
              </Col>
            );
          }
        })()}
      </Row>
    );
    content_block.push(content);
  }
  return content_block;
}

function getBootstrapBreakpoint() {
  var w = window.innerWidth;
  return w < 900 ? 1 : w < 1440 ? 2 : 3;
}

function FeedbackWindow(props) {
  const [feedbackLvl, setLvl] = useState(5);
  const [feedbackText, setFeedbackText] = useState("");
  const { t } = useTranslation();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("feedback.send-therapist")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <center>
            <h4>{t("feedback.feedback")}</h4>
          </center>
          <Form.Label>{t("feedback.target")}(0-10)</Form.Label>
          <Form.Range
            min="0"
            max="10"
            className="range-slider custom-range"
            value={feedbackLvl}
            onChange={(e) => setLvl(e.target.value)}
          />
          <Form.Label className="float-left">{t("feedback.bad")}</Form.Label>
          <Form.Label className="float-right">{t("feedback.good")}</Form.Label>
        </Form.Group>
        <Form.Group>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder={t("feedback.placeholder")}
            onChange={(e) => setFeedbackText(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            sendFeedback(feedbackLvl, feedbackText, props.program);
            props.onHide();
          }}
          className="bs-button-color"
        >
          {t("actions.send")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function sendFeedback(lvl, feedbacktext, id) {
  const url_feedback =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    "/de/v2/" +
    getAccessToken() +
    "/program_session.json";
  var params = new FormData();
  params.append("program_session[pain_level]", lvl);
  params.append("program_session[program_id]", id);
  params.append("program_session[program_feedback", feedbacktext);
  axios
    .post(url_feedback, params)
    .then(function (response) {
      //improve via state
      window.location.reload();
    })
    .catch(function (error) {});
}

function workoutDoneForToday(last_session) {
  let today = new Date();

  if (
    last_session != null &&
    last_session.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)
  ) {
    return true;
  }
  return false;
}

function Program() {
  const { id } = useParams();
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/v2/${getAccessToken()}/programs/${id}`;
  const url_exercises =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    "/de/v2/" +
    getAccessToken() +
    "/programs/get_program_data";

  const [modalShow, setModalShow] = useState(false);
  const [modalProgram, setModalProgramShow] = useState(false);
  const [showSlideshow, setShowSlideshow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  let program = useAxiosGet(url);
  let exercises = useAxiosGet(url_exercises);
  let sorted_program_sessions;
  let content = null;

  const handleClick = async (e) => {
    let last_session = null;
    if (typeof sorted_program_sessions === "undefined") {
      last_session = sorted_program_sessions[0].created_at;
    }

    if (workoutDoneForToday(last_session)) {
      alert("Maximal eine Session pro Tag erlaubt");
    } else {
      setModalShow(true);
    }
  };

  const startSlideshow = async (e) => {
    setShowSlideshow(true);
    console.log(showSlideshow);
  };

  const handleNext = (props) => {
    console.log(props);
    if (currentIndex < props.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const { t } = useTranslation();

  let feedback_text = t("feedback.program-today");
  let disabled = "";

  if (program.error) {
    content = <div>Programm nicht gefunden</div>;
  }

  if (program.loading) {
    content = <Loader />;
  }

  if (program.data) {
    let exercise_data = renderExercises(exercises.data, id);
    let exercise_data_row = setExerciseLayout(
      exercise_data,
      getBootstrapBreakpoint()
    );
    let status_program = isActive(
      program.data.created_at,
      program.data.duration
    );

    let status;
    let variant;
    if (status_program === true) {
      status = "active";
      variant = "success";
    } else {
      status = "inactive";
      variant = "danger";
    }
    let program_sessions = getProgramSessions(exercises.data, id);
    sorted_program_sessions = program_sessions
      ?.map((obj) => {
        return { ...obj, created_at: new Date(obj.created_at) };
      })
      .sort((a, b) => b.created_at - a.created_at);
    let program_done_button, program_done_button_mobile;
    let slider_button;
    if (status === "active") {
      if (workoutDoneForToday(sorted_program_sessions[0]?.created_at)) {
        disabled = "btn-disabled";
        feedback_text = t("feedback.program-today-done");
      }
      program_done_button = (
        <Row className="program-function">
          <Col>
            <center>
              <button
                type="submit"
                className={
                  "btn-feedback-program btn-feedback-program-in-properties " +
                  disabled
                }
                onClick={() => handleClick()}
                style={{ cursor: "pointer" }}
              >
                {feedback_text}
              </button>
            </center>
          </Col>
        </Row>
      );
      program_done_button_mobile = (
        <Row className="program-finished d-block d-md-none">
          <Col>
            <center>
              <button
                type="submit"
                className={"btn-feedback-program " + disabled}
                onClick={() => handleClick()}
                style={{ cursor: "pointer" }}
              >
                {feedback_text}
              </button>
            </center>
          </Col>
        </Row>
      );
      slider_button = (
        <Row className="">
          <Col>
            <center>
              <button
                type="submit"
                className={"btn-feedback-program " + disabled}
                onClick={() => startSlideshow()}
                style={{ cursor: "pointer" }}
              >
                {t("feedback.startSlideShow")}
              </button>
            </center>
          </Col>
        </Row>
      );
    }
    content = (
      <Container fluid className="h-100">
        <Row className="h-100">
          <Col md={4} lg={3} xl={2} className="program-properties h-md-100">
            <Row>
              <Breadcrumb className="breacrumb">
                <Breadcrumb.Item href="/dashboard">
                  {" "}
                  &lt; {t("program.dashboard")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            <Row>
              <Col lg={12} className="program-title">
                {parse(program.data.name)}
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="program-status">
                {status_program ? (
                  <div style={{ color: "green" }}>
                    <b>{t("program.active")}</b>
                  </div>
                ) : (
                  <div style={{ color: "red" }}>
                    <b>({t("program.expired")})</b>
                  </div>
                )}
              </Col>
            </Row>
            <Row className={"program-progress-title-" + status}>
              <Col md={12}>
                {t("program.program-progress")}{" "}
                {(
                  (program_sessions.length /
                    (program.data.frequency * program.data.duration)) *
                  100
                ).toFixed(2)}{" "}
                %
              </Col>
            </Row>
            <Row className="program-progress-graph justify-content-md-center">
              <ProgressBar
                variant={variant}
                now={
                  (program_sessions.length /
                    (program.data.frequency * program.data.duration)) *
                  100
                }
                style={{ width: "90%" }}
                title={
                  (
                    (program_sessions.length /
                      (program.data.frequency * program.data.duration)) *
                    100
                  ).toFixed(2) + "%"
                }
              />
            </Row>
            <Row>
              <Col md={12} className="program-property">
                {t("program.target")} <b>{getTargetName(program.data.goal)}</b>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="program-property">
                {t("program.frequency")}{" "}
                <b>{programInterval(program.data.frequency)}</b>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="program-property">
                {t("program.duration")}{" "}
                <b>
                  {program.data.duration} {t("program.weeks")}
                </b>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="program-property">
                {t("program.end")}{" "}
                <b>
                  {new Date(
                    getEndDateOfProgram(
                      program.data.created_at,
                      program.data.duration
                    )
                  ).toLocaleDateString("de-DE")}
                </b>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="program-property">
                {t("program.note")}{" "}
                <b>
                  {program.data.description
                    ? program.data.description
                    : t("program.no-notes")}
                </b>
              </Col>
            </Row>
            {slider_button}
            {program_done_button}
            {program_done_button_mobile}
          </Col>
          <Col md={8} lg={9} xl={10} className="exercise-details-wrapper">
            {exercise_data_row}
            {program_done_button_mobile}
          </Col>
          <Col className="last-of-type">-</Col>
        </Row>
        <FeedbackWindow
          show={modalShow}
          onHide={() => setModalShow(false)}
          key={program.data.id}
          program={id}
        ></FeedbackWindow>
        <ProgramModal
          show={modalProgram}
          onHide={() => setModalProgramShow(false)}
          key={program.data.name}
          program={id}
          exercises={exercise_data}
        ></ProgramModal>
        <SlideshowModal
          show={showSlideshow}
          video={exercise_data[currentIndex]}
          onHide={() => setShowSlideshow(false)}
          onNext={() => handleNext(exercise_data)}
          onPrevious={() => handlePrevious(exercise_data)}
          exercises={exercise_data[currentIndex]}
          currentIndex={currentIndex}
          programLength={exercise_data.length}
          // onSubmitFeedback={handleFeedbackSubmit}
        />
      </Container>
    );
  }

  return <div className="h-100">{content}</div>;
}

export default Program;
