import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import "./Preferences.css";

export default function Preferences() {
  const { t, i18n } = useTranslation(); //eslint-disable-line no-unused-vars
  return (
    <Container fluid className="about-sop">
      <Row>
        <Col md={10}>
          <li>Inhaber: hoferdigital gmbh, Kirchen 510, 6311 Oberau</li>
          <li>UID-Nummer: ATU58137625</li>
          <li>hello@sophyapp.com</li>
          <center>
            <div className="disclaimer">
              <p>{t("others.about")}</p>
              <p>{t("others.have-fun")}</p>
              <p>{t("others.sop-so-smart")}</p>
            </div>
          </center>
        </Col>
      </Row>
    </Container>
  );
}
